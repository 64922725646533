import arborecImage from "./factions/arborec.png";
import argentImage from "./factions/argent.png";
import baronyImage from "./factions/barony.png";
import cabalImage from "./factions/cabal.png";
import empyreanImage from "./factions/empyrean.png";
import ghostsImage from "./factions/ghosts.png";
import hacanImage from "./factions/hacan.png";
import jolnarImage from "./factions/jolnar.png";
import keleresImage from "./factions/keleres.png";
import l1z1xImage from "./factions/l1z1x.png";
import mahactImage from "./factions/mahact.png";
import mentakImage from "./factions/mentak.png";
import muaatImage from "./factions/muaat.png";
import naaluImage from "./factions/naalu.png";
import naazrokhaImage from "./factions/naazrokha.png";
import nekroImage from "./factions/nekro.png";
import nomadImage from "./factions/nomad.png";
import saarImage from "./factions/saar.png";
import sardakkImage from "./factions/sardakk.png";
import solImage from "./factions/sol.png";
import titansImage from "./factions/titans.png";
import winnuImage from "./factions/winnu.png";
import xxchaImage from "./factions/xxcha.png";
import yinImage from "./factions/yin.png";
import yssarilImage from "./factions/yssaril.png";
import unknownImage from "./factions/unknown.png";

export const factionsAssets = {
  _unknown: {
    src: unknownImage,
    alt: "unknown",
  },
  arborec: {
    src: arborecImage,
    alt: "arborec",
  },
  argent: {
    src: argentImage,
    alt: "argent",
  },
  barony: {
    src: baronyImage,
    alt: "barony",
  },
  cabal: {
    src: cabalImage,
    alt: "cabal",
  },
  empyrean: {
    src: empyreanImage,
    alt: "empyrean",
  },
  ghosts: {
    src: ghostsImage,
    alt: "ghosts",
  },
  hacan: {
    src: hacanImage,
    alt: "hacan",
  },
  jolnar: {
    src: jolnarImage,
    alt: "jolnar",
  },
  keleres: {
    src: keleresImage,
    alt: "keleres",
  },
  l1z1x: {
    src: l1z1xImage,
    alt: "l1z1x",
  },
  mahact: {
    src: mahactImage,
    alt: "mahact",
  },
  mentak: {
    src: mentakImage,
    alt: "mentak",
  },
  muaat: {
    src: muaatImage,
    alt: "muaat",
  },
  naalu: {
    src: naaluImage,
    alt: "naalu",
  },
  naazrokha: {
    src: naazrokhaImage,
    alt: "naazrokha",
  },
  nekro: {
    src: nekroImage,
    alt: "nekro",
  },
  nomad: {
    src: nomadImage,
    alt: "nomad",
  },
  saar: {
    src: saarImage,
    alt: "saar",
  },
  sardakk: {
    src: sardakkImage,
    alt: "sardakk",
  },
  sol: {
    src: solImage,
    alt: "sol",
  },
  titans: {
    src: titansImage,
    alt: "titans",
  },
  winnu: {
    src: winnuImage,
    alt: "winnu",
  },
  xxcha: {
    src: xxchaImage,
    alt: "xxcha",
  },
  yin: {
    src: yinImage,
    alt: "yin",
  },
  yssaril: {
    src: yssarilImage,
    alt: "yssaril",
  },
};
